<!--
 * @Description: 绿名单申报
 * @Author: liqin
 * @Date: 2021-12-24 08:50:57
-->
<template>
  <div class="minH">
    <div class="nartL">
      <el-button @click="href" class="declare">绿名单申报</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headerNmaeCh: '<p style="color: #57802b;">Participation Guide</p>',
      headerNmae:
        '<p style="color: #57802b; letter-spacing:2px; font-size: 25px;">《房地产绿色供应链行动参与指南》</p>',
    }
  },
  mounted() {
    console.log(this.$route.meta.flagNam);
    this.hub.$emit('tatleNames')
    this.hub.$emit('theCover', this.$route.meta.flagNam)

    this.hub.$emit('scrollToz')
  },
  methods: {
      href() {
          window.location.href = 'http://greenlist.see.org.cn:8077/user/Login.aspx'
      }
  }
}
</script>
<style lang="less" scoped>
.minH {
  min-height: 500px;
  .nartL {
      margin: 50px;
  }
  .declare {
    background: #55a11b;
    color: #fff;
  }
}
</style>